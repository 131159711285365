import React from 'react'
import styled from 'styled-components'
import { hsl } from 'polished'

import arrow from '../images/icons/back-light.svg'

const Wrapper = styled.div`
  position: relative;
  display: inline-block;
  margin: 10px;
  width: 320px;
  transition: all 200ms ease-in-out;
  &:after {
    transition: all 200ms ease-in-out;
    position: absolute;
    display: block;
    width: 50px;
    height: 50px;
    top: 0;
    right: 0;
    background-color: ${hsl(0, 0, 0.87)};
    background-image: url('${arrow}');
    background-size: 33px;
    background-repeat: no-repeat;
    transform: rotate(-90deg);
    background-position: center;
    pointer-events: none;
    content: '';
  }
    &:hover {
      &:after {
        background-color: ${hsl(200, 0.99, 0.5)};
      }
    }

  @media (max-width: 768px) {
    width: calc(100% - 20px);
  }
`

const Dropdown = styled.select`
  position: relative;
  display: block;
  width: 100%;
  height: 50px;
  padding: 10px 18px;
  border: none;
  background-color: #fff;
  border: 1px solid ${hsl(0, 0, 0.87)};
  border-radius: 0 !important;
  font-weight: 300;
  font-size: 14px;
  -webkit-appearance: none;
  transition: all 200ms ease-in-out;
  cursor: pointer;

  &:hover {
    border: 1px solid ${hsl(200, 0.99, 0.5)};
  }
`

const Select = ({ children, ...rest }) => (
  <Wrapper>
    <Dropdown {...rest}>{children}</Dropdown>
  </Wrapper>
)

export default Select
