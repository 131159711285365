import React, { useContext, useState, useEffect } from 'react'

import Section from '../components/Section'
import Button from '../components/Button'
import { H2 } from '../components/Headlines'
import Select from '../components/Select'
import { H1 } from '../components/Headlines'
import Checkbox from '../components/Checkbox'
import { Input } from '../components/Input'
import { SelectWrapper } from '../components/SelectWrapper'
import Calendar from '../components/Calendar'

import { Tab, Tabs, TabList, TabPanel } from 'react-tabs'
import 'react-tabs/style/react-tabs.css'

import { Step } from '../components/Step'
import { AppContext } from '../contexts/AppContext'
import ButtonLink from './ButtonLink'

export const TabSection = () => {
  const {
    salutation,
    setSalutation,
    title,
    setTitle,
    firstName,
    setFirstName,
    lastName,
    setLastName,
    street,
    setStreet,
    zipCode,
    setZipCode,
    city,
    setCity,
    phoneNumber,
    setPhoneNumber,
    mobileNumber,
    setMobileNumber,
    email,
    setEmail,
    brand,
    setBrand,
    modell,
    setModell,
    vehicleProcurement,
    setVehicleProcurement,
    procurementMethod,
    setProcurementMethod,
  } = useContext(AppContext)

  const [selectedIndex, setSelectedIndex] = useState(0)

  const [step2isValid, setStep2IsValid] = useState(true)
  const [step3isValid, setStep3IsValid] = useState(false)

  useEffect(() => {
    setStep2IsValid(true)
    // setStep2IsValid(brand && modell && vehicleProcurement && procurementMethod);
  }, [brand, modell, vehicleProcurement, procurementMethod])

  useEffect(() => {
    setStep3IsValid(
      salutation &&
        firstName &&
        lastName &&
        street &&
        zipCode &&
        city &&
        phoneNumber
    )
  }, [salutation, firstName, lastName, street, zipCode, city, phoneNumber])

  return (
    <Tabs
      selectedIndex={selectedIndex}
      onSelect={index => setSelectedIndex(index)}
    >
      <Section>
        <H1>Ihre kostenlose Probefahrt mit der A-Klasse</H1>
        <TabList>
          <Tab>
            <Step number={1} />
            Wunschtermin auswählen
          </Tab>
          <Tab>
            <Step number={2} />
            Fahrzeuginformationen
          </Tab>
          <Tab>
            <Step number={3} />
            Persönliche Daten
          </Tab>
        </TabList>
      </Section>
      <TabPanel>
        <Section variant="grey">
          <H2>Wählen Sie Ihren Wunschtermin </H2>

          <Calendar onNextClick={() => setSelectedIndex(1)} />
        </Section>
      </TabPanel>

      <TabPanel>
        <Section variant="grey">
          <H2>Ihr derzeitiges Fahrzeug </H2>
          <SelectWrapper>
            <Input
              type="text"
              name="brand"
              value={brand}
              onChange={e => setBrand(e.target.value)}
              required
              placeholder="Marke"
            />
            <Input
              type="text"
              name="modell"
              value={modell}
              onChange={e => setModell(e.target.value)}
              required
              placeholder="Modell"
            />
          </SelectWrapper>

          <H2 padding="top">Planen Sie derzeit eine Fahrzeugbeschaffung?</H2>
          <SelectWrapper>
            <Select
              required
              name="vehicleProcurement"
              value={vehicleProcurement}
              onChange={e => setVehicleProcurement(e.target.value)}
            >
              <option value="" disabled selected>
                Fahrzeugbeschaffung geplant
              </option>
              <option value="vehicle-procurement-yes">Ja</option>
              <option value="vehicle-procurement-no">Nein</option>
            </Select>
            <Select
              required
              name="procurement"
              name="procurementMethod"
              value={procurementMethod}
              onChange={e => setProcurementMethod(e.target.value)}
            >
              <option value="" disabled selected>
                Wollen Sie es... ?
              </option>
              <option value="procurement-finance">finanzieren</option>
              <option value="procurement-lease">leasen</option>
              <option value="procurement-buy">kaufen</option>
            </Select>
          </SelectWrapper>
          <SelectWrapper padding="top">
            <Button
              disabled={!step2isValid}
              onClick={() => setSelectedIndex(2)}
            >
              weiter
            </Button>
          </SelectWrapper>
        </Section>
      </TabPanel>
      <TabPanel>
        <Section variant="grey">
          <H2>Persönliche Daten</H2>
          <SelectWrapper>
            <Select
              required
              name="selectSalutation"
              name="salutation"
              value={salutation}
              onChange={e => setSalutation(e.target.value)}
            >
              <option value="" disabled selected>
                Anrede*
              </option>
              <option value="Frau">Frau</option>
              <option value="Herr">Herr</option>
              <option value="Divers">Divers</option>
            </Select>
            <Select
              name="selectTitle"
              name="title"
              value={title}
              onChange={e => setTitle(e.target.value)}
            >
              <option value="" selected>
                Titel
              </option>
              <option value="dr">Dr.</option>
              <option value="prof">Prof. Dr.</option>
            </Select>
          </SelectWrapper>

          <SelectWrapper>
            <Input
              required
              name="firstName"
              placeholder="Vorname*"
              value={firstName}
              onChange={e => setFirstName(e.target.value)}
            />
            <Input
              required
              name="lastName"
              placeholder="Nachname*"
              value={lastName}
              onChange={e => setLastName(e.target.value)}
            />
          </SelectWrapper>

          <SelectWrapper>
            <Input
              required
              placeholder="Straße/Hausnummer*"
              name="street"
              value={street}
              onChange={e => setStreet(e.target.value)}
            />
            <Input
              required
              placeholder="PLZ*"
              name="zipCode"
              value={zipCode}
              onChange={e => setZipCode(e.target.value)}
            />
          </SelectWrapper>

          <SelectWrapper>
            <Input
              required
              placeholder="Wohnort*"
              name="city"
              value={city}
              onChange={e => setCity(e.target.value)}
            />
            <Input
              required
              placeholder="Telefon*"
              name="phoneNumber"
              value={phoneNumber}
              onChange={e => setPhoneNumber(e.target.value)}
            />
          </SelectWrapper>

          <SelectWrapper>
            <Input
              placeholder="Mobil"
              name="mobileNumber"
              value={mobileNumber}
              onChange={e => setMobileNumber(e.target.value)}
            />
            <Input
              placeholder="E-Mail"
              name="email"
              value={email}
              onChange={e => setEmail(e.target.value)}
            />
          </SelectWrapper>
          <Checkbox
            label={[
              'Ich habe die ',
              <a
                href="https://www.sug.de/datenschutz/"
                target="_blank"
                rel="noopener noreferrer"
              >
                Datenschutzerklärung
              </a>,
              ' gelesen und akzeptiert.',
            ]}
          />
          <SelectWrapper>
            <ButtonLink
              icon="none"
              to="/danke/"
              disabled={!step3isValid}
              className={`button ${step3isValid ? '' : 'disabled'}`}
            >
              Jetzt Wunschtermin anfragen
            </ButtonLink>
          </SelectWrapper>
        </Section>
      </TabPanel>
    </Tabs>
  )
}
