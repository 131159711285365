import 'moment/locale/de.js'
import 'moment-timezone'
import 'rc-datepicker/lib/style.css'

import React, { useContext, useState, useEffect } from 'react'
import { DatePicker } from 'rc-datepicker'
import styled from 'styled-components'
import momentBusinessDays from 'moment-business-days'

import Button from '../components/Button'
import Select from '../components/Select'
import { Input } from '../components/Input'
import { SelectWrapper } from '../components/SelectWrapper'

import { AppContext } from '../contexts/AppContext'

const TimePicker = styled.div`
  width: 320px;
  display: inline-block;
  margin-left: 20px;
  @media (max-width: 768px) {
    margin-left: 0;
    width: 100%;
  }
`

const TimeWrapper = styled.div`
  @media (max-width: 768px) {
    display: none;
  }
`

const TimeSelect = styled.div`
  @media (min-width: 769px) {
    display: none;
  }
`

const Wrapper = styled.div`
  position: relative;
  margin: 20px 0;
  ${Button} {
    position: absolute;
    bottom: 0;
    margin: 0 10px;
    @media (max-width: 768px) {
      position: relative;
      margin: 10px;
    }
  }
`

const Calendar = props => {
  const { dateDay, setDateDay, dateTime, setDateTime } = useContext(AppContext)

  const [isValid, setIsValid] = useState(false)

  useEffect(() => {
    setIsValid(dateDay && dateTime)
  }, [dateDay, dateTime])

  return (
    <Wrapper>
      <SelectWrapper className="hidden">
        <fieldset>
          <Input
            disabled="disabled"
            required
            placeholder="Wunschtermin auswählen*"
          />
          <span className="date" />
        </fieldset>
        <fieldset className="timeFieldset">
          <Input disabled="disabled" required placeholder="Uhrzeit auswählen" />
          <span className="time" />
        </fieldset>
      </SelectWrapper>
      <DatePicker
        className="react-datepicker"
        locale="de"
        value={dateDay}
        minDate={momentBusinessDays().businessAdd(2).toDate()}
        onChange={dateString => setDateDay(dateString)}
      />
      <TimePicker>
        <TimeSelect>
          <SelectWrapper>
            <Select
              name="time"
              value={dateTime}
              onChange={e => setDateTime(e.target.value)}
            >
              <option value="" disabled>
                Uhrzeit auswählen
              </option>
              <option value="08:00">08:00 Uhr</option>
              <option value="09:00">09:00 Uhr</option>
              <option value="10:00">10:00 Uhr</option>
              <option value="11:00">11:00 Uhr</option>
              <option value="12:00">12:00 Uhr</option>
              <option value="14:00">14:00 Uhr</option>
              <option value="15:00">15:00 Uhr</option>
              <option value="16:00">16:00 Uhr</option>
            </Select>
          </SelectWrapper>
        </TimeSelect>
        <TimeWrapper>
          <SelectWrapper>
            <label>
              <Input
                type="radio"
                name="time"
                size="small"
                checked={dateTime === '08:00'}
                onChange={() => setDateTime('08:00')}
              />
              <span>08:00 Uhr</span>
            </label>
            <label>
              <Input
                type="radio"
                name="time"
                size="small"
                checked={dateTime === '09:00'}
                onChange={() => setDateTime('09:00')}
              />
              <span>09:00 Uhr</span>
            </label>
          </SelectWrapper>
          <SelectWrapper>
            <label>
              <Input
                type="radio"
                name="time"
                size="small"
                checked={dateTime === '10:00'}
                onChange={() => setDateTime('10:00')}
              />
              <span>10:00 Uhr</span>
            </label>
            <label>
              <Input
                type="radio"
                name="time"
                size="small"
                checked={dateTime === '11:00'}
                onChange={() => setDateTime('11:00')}
              />
              <span>11:00 Uhr</span>
            </label>
          </SelectWrapper>
          <SelectWrapper>
            <label>
              <Input
                type="radio"
                name="time"
                size="small"
                checked={dateTime === '12:00'}
                onChange={() => setDateTime('12:00')}
              />
              <span>12:00 Uhr</span>
            </label>
            <label>
              <Input
                type="radio"
                name="time"
                size="small"
                checked={dateTime === '14:00'}
                onChange={() => setDateTime('14:00')}
              />
              <span>14:00 Uhr</span>
            </label>
          </SelectWrapper>
          <SelectWrapper>
            <label>
              <Input
                type="radio"
                name="time"
                size="small"
                checked={dateTime === '15:00'}
                onChange={() => setDateTime('15:00')}
              />
              <span>15:00 Uhr</span>
            </label>
            <label>
              <Input
                type="radio"
                name="time"
                size="small"
                checked={dateTime === '16:00'}
                onChange={() => setDateTime('16:00')}
              />
              <span>16:00 Uhr</span>
            </label>
          </SelectWrapper>
        </TimeWrapper>
        <SelectWrapper>
          <Button disabled={!isValid} onClick={props.onNextClick}>
            weiter
          </Button>
        </SelectWrapper>
      </TimePicker>
    </Wrapper>
  )
}

export default Calendar
