import styled, { css } from 'styled-components'
import { hsl } from 'polished'

export const Input = styled.input`
  position: relative;
  display: inline-block;
  margin: 10px;
  width: 320px;
  height: 50px;
  padding: 10px 18px;
  border: none;
  background-color: #fff;
  border: 1px solid ${hsl(0, 0, 0.87)};
  border-radius: 0 !important;
  font-weight: 300;
  font-size: 14px;
  -webkit-appearance: none;
  transition: all 200ms linear;
  &:focus {
    border: 1px solid ${hsl(200, 0.99, 0.5)};
  }
  @media (max-width: 768px) {
    width: calc(100% - 20px);
  }
  &[type='radio']:checked {
    background-color: ${hsl(200, 0.99, 0.5)}!important;
    border-color: ${hsl(200, 0.99, 0.5)}!important;
  }
  &[type='radio']:checked + span {
    color: white !important;
  }
  ${props =>
    props.disabled === 'disabled' &&
    css`
      background-color: ${hsl(0, 0, 0.87)};
    `}
  ${props =>
    props.size === 'small' &&
    css`
      width: calc(100% - 20px);
      text-align: center;
      &:hover {
        background-color: ${hsl(200, 0.99, 0.5)};
        border-color: ${hsl(200, 0.99, 0.5)};
        color: white;
        cursor: pointer;
        + span {
          color: white;
        }
      }
    `}
`
