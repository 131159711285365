import React from 'react'

import Layout from '../components/layout'
import SEO from '../components/seo'
import { Hero } from '../components/Hero'
import { TabSection } from '../components/Tabs'

import HeaderImage from '../images/a-class-limousine/header-a-class-limousine.jpeg'

const TestDrivePage = () => (
  <Layout>
    <SEO title="Probefahrt" keywords={[`sug`, `probefahrt`]} />
    <Hero bgImage={HeaderImage} />
    <TabSection />
  </Layout>
)

export default TestDrivePage
