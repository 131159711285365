import React from 'react'
import styled from 'styled-components'
import { between, hsl } from 'polished'

import Icon from '../images/icons/checkmark.svg'

const Checkmark = styled.span`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  height: 20px;
  width: 20px;
  margin: 2px 5px 5px 0;
  border: 1px solid ${hsl(200, 0.99, 0.5)};
  background-color: transparent;
  transition: all 200ms cubic-bezier(0.2, 0.54, 0.58, 0.7);

  &:after {
    display: none;
    width: 19px;
    height: 19px;
    background-image: url('${Icon}');
    background-repeat: no-repeat;
    content: '';
  }

  &:hover {
    border-color: ${hsl(200, 0.99, 0.5)}!important;
  }

  &:focus {
    border-color: ${hsl(200, 0.99, 0.5)};!important;
  }
`

const Label = styled.label`
  position: relative;
  display: inline-block;
  text-align: left;
  width: calc(100% - 30px);
  margin: 20px 15px 30px 0;
  padding-left: 35px;
  font-size: ${between('14px', '16px', '320px', '2560px')};
  font-weight: 100;
  line-height: 25px;
  cursor: pointer;
  user-select: none;
  color: #000;

  &:focus,
  span:focus {
    color: ${hsl(198, 1, 0.45)};
  }
  @media (max-width: 768px) {
    margin: 10px 0;
    width: 100%;
  }
  input {
    position: absolute;
    height: 0;
    width: 0;
    opacity: 0;
    cursor: pointer;

    &:checked ~ ${Checkmark} {
      border-color: ${hsl(200, 0.99, 0.5)};
      &:after {
        display: block;
      }
    }
  }
  a {
    text-decoration: underline;
    &:hover {
      color: ${hsl(200, 0.99, 0.5)};
    }
  }
`

const Checkbox = ({ label, ...rest }) => (
  <Label {...rest}>
    {label}
    <input type="checkbox" name="checkbox" />
    <Checkmark />
  </Label>
)

export default styled(Checkbox)``
