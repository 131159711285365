import React from 'react'
import styled from 'styled-components'
import { hsl } from 'polished'

const Wrapper = styled.div`
  width: 40px;
  height: 40px;
  background-color: ${hsl(0, 0, 0.87)};
  border-radius: 50px;
  display: inline-flex;
  justify-content: center;
  flex-direction: column;
  margin-right: 20px;
  font-size: 16px;
  font-weight: 500;
  span {
    margin: 0 auto;
    color: ${hsl(226, 0.05, 0.48)};
  }
`

export const Step = ({ number }) => (
  <Wrapper>
    <span>{number}</span>
  </Wrapper>
)
