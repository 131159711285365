import React from 'react'
import styled, { css } from 'styled-components'
import { hsl, hsla } from 'polished'

import Arrow from '../images/icons/arrow.svg'

const Wrapper = styled.button`
  padding: 18px 30px;
  width: 320px;
  max-width: 100%;
  background-color: ${hsl(200, 0.99, 0.5)};
  color: #fff;
  border: none;
  line-height: 1;
  font-weight: 500;
  transition: all 200ms linear;
  letter-spacing: 1.2px;
  font-size: 14px;
  cursor: pointer;
  margin: 10px;
  box-shadow: 1px 10px 20px 0px ${hsla(200, 0.99, 0.5, 0.4)};

  &:hover {
    background-color: ${hsl(198, 1, 0.35)};
  }

  :disabled {
    opacity: 0.4;
  }

  span {
    display: flex;
    align-items: center;
    justify-content: center;
    font-weight: 500;

    &:after {
      position: relative;
      display: block;
      width: 25px;
      height: 13px;
      background-image: url(${Arrow});
      background-repeat: no-repeat;
      content: '';
      margin-left: 15px;
      margin-right: -15px;
    }
  }

  ${props =>
    props.icon === 'none' &&
    css`
      font-size: 12px;
      span {
        &:after {
          display: none;
        }
      }
    `}

  @media (max-width: 768px) {
    width: calc(100% - 20px);
  }
`

const Button = ({ children, ...rest }) => (
  <Wrapper {...rest}>
    <span>{children}</span>
  </Wrapper>
)

export default styled(Button)``
